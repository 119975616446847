import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgLogo from "../images/logo-white-alpha-large.png"
import imgCoach from "../images/coach-blue-blur.jpg"
import imgSupport from "../images/support.jpg"
import "./index.css";
import Helmet from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FooterMin from "../components/footer-min"
import Hero from "../components/hero-standard"
import Nav from "../components/nav"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Request information" />
        <Nav />
        <div>
          <div>

            <Hero h1="Request more info" h2="Get your hands on an information pack" />

            <div className="article">
              <div className='article-inner'>
                <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                  Submit your details below and we'll send you more detailed information about our packages.
                </p>
                <form action="/info-requested/" name="request-info" method="POST" className="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
                  <p style={{ display: 'none' }}>
                    <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                  </p>
                  <div className="contact-form__group">
                    <label>Name</label>
                    <input name="name" placeholder="Your name" required className="contact-form__input" />
                  </div>
                  <div className="contact-form__group">
                    <label>Company</label>
                    <input name="company" placeholder="Company" required className="contact-form__input" />
                  </div>
                  <div className="contact-form__group">
                    <label>Email</label>
                    <input name="email" placeholder="Email" type="email" required className="contact-form__input" />
                  </div>
                  <div className="contact-form__group">
                    <label>Phone</label>
                    <input name="phone" placeholder="Phone" required className="contact-form__input" />
                  </div>
                  <input type="hidden" name="form-name" value="request-info" />
                  <p style={{ textAlign: 'center', paddingTop: '1em' }}>
                    <button className="btn" type="submit">Request info</button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
        <FooterMin />
      </Layout>
    );
  }
};

export default BlogPage
